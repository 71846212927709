import React from 'react'
import HeroHalfImage from './HeroHalfImage'
import HeroContent from './HeroContent'

const HeroGradiant = ({ cssClass = '' }) => {
    return (
        <div className={`hero gradiant ${cssClass}`}>
            <div className="content">
                <div className='content-column pt'>
                    <HeroContent />
                </div>
                <HeroHalfImage />
            </div>
        </div>
    )
}

export default HeroGradiant
