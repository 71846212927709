import React from "react"
import Layout from "../components/Layout"
import HeroGradiant from '../components/heros/HeroGradiant'

const IndexPage = () => {
  return (
    <Layout
      pageTitle={"San Antonio Web Design & Development"}
      description={
        "San Antonio Web Design & Development"
      }
    >
      <HeroGradiant />
      <section style={{ marginTop: "-60px" }}>
        <div className="content">
          <h2 className="section-title">Maintenence Mode</h2>
          <h3 className="section-subtitle">Site is currently hibernating</h3>
          <p className="center" style={{ fontSize: "1.2em", lineHeight: "1.3em" }}>
            If you have any web design needs please check <a href="https://digibotweb.com">here</a>.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
