import React, {useContext} from 'react'
import DataContext from '../../data/context'
import { Link } from 'gatsby'

const HeroContent = () => {
    const { heroContent } = useContext(DataContext)

    return (
        <div className="hero-content">
            <h1 className="">{heroContent.title}</h1>
            <h2 className="">Are you ready to put the <span>spark</span> in your web presence?</h2>
            <p className="">{heroContent.message}</p>
            <div className="button-group">
                <Link to={heroContent.button1.link} className="button highlight">{heroContent.button1.text}</Link>
                <Link to={heroContent.button2.link} className="button secondary">{heroContent.button2.text}</Link>
            </div>
        </div>
    )
}

export default HeroContent
