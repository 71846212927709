import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HeroHalfImage = () => {
  return (
    <div className="content-column">
      <StaticImage
        src="../../images/dev-results.svg"
        width={800}
        quality={95}
        placeholder="blurred"
        loading="eager"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="hero image"
      />
    </div>
  )
}

export default HeroHalfImage
